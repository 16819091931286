<template>
  <div>
    <div class="tb-view-type">
      <table>
        <colgroup>
          <col style="width: auto">
          <col style="width: 180px">
        </colgroup>
        <thead>
          <tr>
            <th>{{ title }}</th>
            <th><strong>작성일</strong><span>{{ createDate }}</span></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="2">
              <div style="min-height: 499px;">
                <div
                  class="text-in"
                  style="min-height: initial;"
                >
                  <template v-for="(msg, mIdx) in splitMessages(content)">
                    {{ msg }} <br :key="`content_${mIdx}`">
                  </template>
                </div>
                <div class="contents-in">
                  <div
                    v-for="item in attachFileList"
                    :key="item.fileId"
                  >
                    <img
                      :src="item.filePath"
                      style="max-width: 100%;"
                    >
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="flex-r">
      <button
        class="btn-list"
        @click="askList"
      >
        목록으로
      </button>
    </div>

    <div class="tb-pn-view-type">
      <table>
        <colgroup>
          <col style="width: 180px">
          <col style="width: auto">
          <col style="width: 210px">
        </colgroup>
        <tbody>
          <tr v-if="nextAsk">
            <th>다음글</th>
            <td><p><a @click="fetchAskDetail(nextAsk.id, nextAsk.type)">{{ nextAsk.title }}</a></p></td>
            <td>{{ nextAsk.createDate }}</td>
          </tr>
          <tr v-if="prevAsk">
            <th>이전글</th>
            <td><p><a @click="fetchAskDetail(prevAsk.id, prevAsk.type)">{{ prevAsk.title }}</a></p></td>
            <td>{{ prevAsk.createDate }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from '@axios'

export default {
  data() {
    return {
      title: '',
      content: '',
      createDate: '',
      attachFileList: [],
      prevAsk: {},
      nextAsk: {},
    }
  },
  methods: {
    splitMessages(message) {
      return message.split('\n')
    },
    askList() {
      if (this.$route.params.id) {
        this.$router.push({ name: 'app-mypage-askHistory' })
      } else { this.$parent.view = false }
    },
    fetchAskDetail(id, type) {
      axios.get(`/fu/user/ask/${id}`, {
        params: {
          type,
        },
      })
        .then(rs => {
          this.title = rs.data.title
          this.content = rs.data.content
          this.createDate = rs.data.createDate
          this.attachFileList = rs.data.attachFileList

          this.prevAsk = rs.data.prevAsk
          this.nextAsk = rs.data.nextAsk

          this.scrollTop()
        })
        .catch(error => {
          this.$parent.$parent.showAlertDialog(error.response.data)
        })
    },
    scrollTop() {
      const detailWrap = document.querySelectorAll(".sub-wrap")

      detailWrap.forEach(item => {
        item.scrollTo({ top: 0 })
      })
    },
  },
}
</script>
