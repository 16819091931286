<template>
  <div>
    <p class="title">
      문의글 작성
    </p>
    <div class="text-wrap">
      <input
        v-model.trim="title"
        type="text"
        placeholder="제목을 입력해 주세요"
        maxlength="100"
      >
    </div>

    <div class="textarea-wrap">
      <textarea
        v-model.trim="content"
        placeholder="문의내용을 입력해 주세요.(1,000자 이내)"
        maxlength="1000"
      />
    </div>

    <div class="find-add-wrap">
      <input
        ref="uploader"
        type="file"
        accept="image/*"
        multiple
        @change="selectFiles"
      >
      <button class="btn-file-add">
        첨부하기
      </button>
      <label>첨부 파일 등록 (최대 5개의 이미지 파일만 업로드 가능)</label>
    </div>

    <div
      v-if="uploadFiles.length > 0"
      class="file-list"
    >
      <ul>
        <li
          v-for="(file, index) in uploadFiles"
          :key="index"
        >
          <i /><a href="javascript: void(0)">{{ file.name }}</a><button
            class="btn-delete"
            @click="removeFile(file)"
          />
        </li>
      </ul>
    </div>

    <div class="btn-wrap">
      <button
        class="btn-cancel"
        @click.prevent="$parent.write = false"
      >
        취소
      </button>
      <button
        class="btn-ok"
        @click.prevent="saveAsk"
      >
        확인
      </button>
    </div>
  </div>
</template>

<script>
import axios from '@axios'

export default {
  data() {
    return {
      title: '',
      content: '',
      uploadFiles: [],
      isProcessing: false
    }
  },
  methods: {
    saveAsk() {
      if (!this.isProcessing) {
        if (this.title.length === 0) this.$parent.$parent.showAlertDialog('제목을 입력해 주세요.')
        else if (this.content.length === 0) this.$parent.$parent.showAlertDialog('문의내용을 입력해 주세요.')
        else {
          this.isProcessing = true

          const formdata = new FormData()

          formdata.append("title", this.title)
          formdata.append("content", this.content)

          for (let i = 0; i < this.uploadFiles.length; i += 1) {
            formdata.append("files", this.uploadFiles[i])
          }

          axios.post("/fu/user/ask", formdata, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
            .then(() => {
              this.$parent.$parent.showAlertDialog('문의글이 작성되었습니다.', () => {
                this.isProcessing = false

                this.$parent.write = false
                this.$parent.fetchAsk()
              })
            })
            .catch(error => {
              this.isProcessing = false

              this.$parent.$parent.showAlertDialog(error.response.data)
            })
        }
      }
    },
    selectFiles(e) {
      if (this.uploadFiles.length + e.target.files.length > 5) this.$parent.$parent.showAlertDialog('첨부파일은 5개까지 첨부하실 수 있습니다.')
      else {
        let isUpload = true

        Array.from(e.target.files).forEach(file => {
          if (!file.type.includes('image')) {
            this.$parent.$parent.showAlertDialog('이미지 파일만 업로드 하실 수 있습니다.')

            isUpload = false

            return false
          }

          if (file.size > 100 * 1024 * 1024) {
            this.$parent.$parent.showAlertDialog('첨부파일의 크기는 100MB 이하여야 합니다.')

            isUpload = false

            return false
          }

          return true
        })

        if (isUpload) this.uploadFiles.push(...e.target.files)
      }

      this.$refs.uploader.value = ''
    },
    removeFile(file) {
      this.uploadFiles = this.uploadFiles.filter(e => e !== file)
    }
  },
}
</script>
