var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", { staticClass: "title" }, [_vm._v(" 문의글 작성 ")]),
    _c("div", { staticClass: "text-wrap" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.trim",
            value: _vm.title,
            expression: "title",
            modifiers: { trim: true },
          },
        ],
        attrs: {
          type: "text",
          placeholder: "제목을 입력해 주세요",
          maxlength: "100",
        },
        domProps: { value: _vm.title },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.title = $event.target.value.trim()
          },
          blur: function ($event) {
            return _vm.$forceUpdate()
          },
        },
      }),
    ]),
    _c("div", { staticClass: "textarea-wrap" }, [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model.trim",
            value: _vm.content,
            expression: "content",
            modifiers: { trim: true },
          },
        ],
        attrs: {
          placeholder: "문의내용을 입력해 주세요.(1,000자 이내)",
          maxlength: "1000",
        },
        domProps: { value: _vm.content },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.content = $event.target.value.trim()
          },
          blur: function ($event) {
            return _vm.$forceUpdate()
          },
        },
      }),
    ]),
    _c("div", { staticClass: "find-add-wrap" }, [
      _c("input", {
        ref: "uploader",
        attrs: { type: "file", accept: "image/*", multiple: "" },
        on: { change: _vm.selectFiles },
      }),
      _c("button", { staticClass: "btn-file-add" }, [_vm._v(" 첨부하기 ")]),
      _c("label", [
        _vm._v("첨부 파일 등록 (최대 5개의 이미지 파일만 업로드 가능)"),
      ]),
    ]),
    _vm.uploadFiles.length > 0
      ? _c("div", { staticClass: "file-list" }, [
          _c(
            "ul",
            _vm._l(_vm.uploadFiles, function (file, index) {
              return _c("li", { key: index }, [
                _c("i"),
                _c("a", { attrs: { href: "javascript: void(0)" } }, [
                  _vm._v(_vm._s(file.name)),
                ]),
                _c("button", {
                  staticClass: "btn-delete",
                  on: {
                    click: function ($event) {
                      return _vm.removeFile(file)
                    },
                  },
                }),
              ])
            }),
            0
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "btn-wrap" }, [
      _c(
        "button",
        {
          staticClass: "btn-cancel",
          on: {
            click: function ($event) {
              $event.preventDefault()
              _vm.$parent.write = false
            },
          },
        },
        [_vm._v(" 취소 ")]
      ),
      _c(
        "button",
        {
          staticClass: "btn-ok",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.saveAsk($event)
            },
          },
        },
        [_vm._v(" 확인 ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }