var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "tb-view-type" }, [
      _c("table", [
        _vm._m(0),
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v(_vm._s(_vm.title))]),
            _c("th", [
              _c("strong", [_vm._v("작성일")]),
              _c("span", [_vm._v(_vm._s(_vm.createDate))]),
            ]),
          ]),
        ]),
        _c("tbody", [
          _c("tr", [
            _c("td", { attrs: { colspan: "2" } }, [
              _c("div", { staticStyle: { "min-height": "499px" } }, [
                _c(
                  "div",
                  {
                    staticClass: "text-in",
                    staticStyle: { "min-height": "initial" },
                  },
                  [
                    _vm._l(
                      _vm.splitMessages(_vm.content),
                      function (msg, mIdx) {
                        return [
                          _vm._v(" " + _vm._s(msg) + " "),
                          _c("br", { key: "content_" + mIdx }),
                        ]
                      }
                    ),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "contents-in" },
                  _vm._l(_vm.attachFileList, function (item) {
                    return _c("div", { key: item.fileId }, [
                      _c("img", {
                        staticStyle: { "max-width": "100%" },
                        attrs: { src: item.filePath },
                      }),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "flex-r" }, [
      _c("button", { staticClass: "btn-list", on: { click: _vm.askList } }, [
        _vm._v(" 목록으로 "),
      ]),
    ]),
    _c("div", { staticClass: "tb-pn-view-type" }, [
      _c("table", [
        _vm._m(1),
        _c("tbody", [
          _vm.nextAsk
            ? _c("tr", [
                _c("th", [_vm._v("다음글")]),
                _c("td", [
                  _c("p", [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.fetchAskDetail(
                              _vm.nextAsk.id,
                              _vm.nextAsk.type
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.nextAsk.title))]
                    ),
                  ]),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.nextAsk.createDate))]),
              ])
            : _vm._e(),
          _vm.prevAsk
            ? _c("tr", [
                _c("th", [_vm._v("이전글")]),
                _c("td", [
                  _c("p", [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.fetchAskDetail(
                              _vm.prevAsk.id,
                              _vm.prevAsk.type
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.prevAsk.title))]
                    ),
                  ]),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.prevAsk.createDate))]),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "180px" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "180px" } }),
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "210px" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }