<template>
  <div>
    <div
      v-show="view == false"
      class="contact-details-list"
    >
      <div class="date-wrap">
        <div class="radio-date">
          <input
            id="monthCheck5"
            v-model="monthCheck"
            type="radio"
            name="radioDate"
            value="1"
          >
          <label for="monthCheck5">1개월</label>
        </div>
        <div class="radio-date">
          <input
            id="monthCheck6"
            v-model="monthCheck"
            type="radio"
            name="radioDate"
            value="3"
          >
          <label for="monthCheck6">3개월</label>
        </div>
        <div class="radio-date">
          <input
            id="monthCheck7"
            v-model="monthCheck"
            type="radio"
            name="radioDate"
            value="6"
          >
          <label for="monthCheck7">6개월</label>
        </div>
        <div class="radio-date">
          <input
            id="monthCheck8"
            v-model="monthCheck"
            type="radio"
            name="radioDate"
            value="12"
          >
          <label for="monthCheck8">1년</label>
        </div>

        <div
          class="period-calendar-wrap"
          style="display: flex; position: relative"
        >
          <div class="text-wrap calendar-type">
            <date-picker
              v-model="calendarFr"
              :lang="datePicker.lang"
              value-type="format"
            />
          </div>
          <label class="bu-wave" />
          <div class="text-wrap calendar-type">
            <date-picker
              v-model="calendarTo"
              :lang="datePicker.lang"
              value-type="format"
            />
          </div>

          <button
            class="btn-period"
            @click.stop="searchAsk(1)"
          >
            검색
          </button>
        </div>

        <div class="right-col">
          <button
            class="btn-write"
            @click.prevent="write = true"
          >
            문의글 작성
          </button>
        </div>
      </div>

      <div class="tb-list-type">
        <table>
          <colgroup>
            <col style="width: auto">
            <col style="width: 120px">
            <col style="width: 120px">
            <col style="width: 180px">
          </colgroup>

          <thead>
            <tr>
              <th>제목</th>
              <th>작성자</th>
              <th>작성일</th>
              <th>상태</th>
            </tr>
          </thead>
          <tbody>
            <template
              v-for="(item, index) in askList"
            >
              <tr
                v-if="item.type === 'ask'"
                :key="index"
              >
                <td class="txt-left">
                  <p class="ellipsis-txt">
                    <a
                      href="javascript: void(0)"
                      @click="askDetail(item.id, item.type)"
                    >{{ item.title }}</a>
                  </p>
                </td>
                <td>{{ item.createName }}</td>
                <td>{{ item.createDate }}</td>
                <td>{{ item.status }}</td>
              </tr>
              <tr
                v-if="item.type === 'answer'"
                :key="index"
              >
                <td class="txt-left">
                  <p class="ellipsis-txt">
                    <a
                      href="javascript: void(0)"
                      @click="askDetail(item.id, item.type)"
                    ><span class="i-reply">RE :</span>{{ item.title }}</a>
                  </p>
                </td>
                <td>관리자</td>
                <td>{{ item.createDate }}</td>
                <td>&nbsp;</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <pagination
        :page="currentPage"
        :records="totalRecord"
        :per="pageCount"
        @paging="pageUpdate"
      />

      <div class="list-search">
        <div class="select-wrap">
          <select v-model="searchType">
            <option value="Title">
              제목
            </option>
            <option value="Content">
              내용
            </option>
          </select>
        </div>
        <div class="search-bar">
          <div class="text-wrap">
            <input
              v-model="searchKeyword"
              type="text"
              placeholder="키워드를 입력하세요."
              class="search-type"
              maxlength="50"
              @keyup.enter.stop="searchAsk(1)"
            >
            <button
              class="btn-search"
              @click.stop="searchAsk(1)"
            >
              검색
            </button>
          </div>
        </div>
      </div>

      <div
        v-if="write"
        class="contact-write"
      >
        <ask-write />
      </div>
    </div>

    <div
      v-show="view"
      class="contact-details-view"
    >
      <ask-view ref="askView" />
    </div>
  </div>
</template>

<script>
import axios from '@axios'
import Pagination from '@/components/controls/Pagination.vue'
import AskWrite from '@/views/apps/mypage/ask-history/AskWrite.vue'
import AskView from '@/views/apps/mypage/ask-history/AskView.vue'

import DatePicker from 'vue2-datepicker'
import dayjs from 'dayjs'
import '@/libs/vue2-datepicker/calendar-vue.css'
import { locale } from '@/libs/vue2-datepicker/lang/ko-kr'
import { mapGetters } from 'vuex'

export default {
  components: {
    DatePicker,
    Pagination,
    AskWrite,
    AskView,
  },
  data() {
    return {
      askList: [],
      searchType: 'Title',
      searchKeyword: '',
      searchDateFr: null,
      searchDateTo: null,
      calendarFr: null,
      calendarTo: null,
      monthCheck: 3,
      datePicker: {
        lang: locale(),
      },
      currentPage: 1,
      pageCount: 5,
      totalRecord: 0,
      write: false,
      view: false,
    }
  },
  computed: {
    ...mapGetters({
      params: 'util/getParams',
    }),
  },
  watch: {
    currentPage() {
      this.fetchAsk(this.currentPage)
    },
    monthCheck() {
      this.calendarFr = dayjs().add(-this.monthCheck, 'month').format('YYYY-MM-DD')
      this.calendarTo = dayjs().format('YYYY-MM-DD')
    },
    calendarFr(now, old) {
      if (now > this.calendarTo) {
        this.calendarFr = old

        this.$parent.showAlertDialog("시작일은 종료일보다 클 수 없습니다.")
      }
    },
    calendarTo(now, old) {
      if (now < this.calendarFr) {
        this.calendarTo = old

        this.$parent.showAlertDialog("종료일은 시작일보다 작을 수 없습니다.")
      }
    },
    params() {
      if (this.params && this.params.route === this.$route.name) this.askDetail(this.params.id, 'answer')
    }
  },
  created() {
    this.calendarFr = dayjs().add(-this.monthCheck, 'month').format('YYYY-MM-DD')
    this.calendarTo = dayjs().format('YYYY-MM-DD')

    this.searchAsk(1)
  },
  mounted() {
    if (this.params) this.askDetail(this.params.id, 'answer')
  },
  methods: {
    searchAsk(page = this.currentPage) {
      if (this.calendarFr === null) this.$parent.showAlertDialog("시작일을 선택해주세요.")
      else if (this.calendarTo === null) this.$parent.showAlertDialog("종료일을 선택해주세요.")
      else {
        if (page !== this.currentPage) this.currentPage = page

        this.searchDateFr = this.calendarFr
        this.searchDateTo = this.calendarTo

        this.fetchAsk(page)
      }
    },
    fetchAsk(page) {
      axios.post("/fu/user/ask/list", {
        search: {
          searchType: this.searchType,
          searchKeyword: this.searchKeyword,
          searchDateFr: this.searchDateFr,
          searchDateTo: this.searchDateTo,
        },
        pagination: {
          number: page,
          count: this.pageCount,
        },
      })
        .then(rs => {
          const { items, totalRecord } = rs.data

          this.askList = items
          this.totalRecord = totalRecord
        })
        .catch(error => {
          this.$parent.showAlertDialog(error.response.data)
        })
    },
    askDetail(id, type) {
      this.view = true

      this.$refs.askView.fetchAskDetail(id, type)

      if (this.params) this.$store.commit('util/setParams', null)
    },
    pageUpdate(page) {
      this.currentPage = page
    },
  },
}
</script>
